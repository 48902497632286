<template>
  <el-tabs type="border-card" style="height: 100%" @tab-click="handleClick">
    <el-tab-pane label="Details"><span><strong>N° de série :</strong> {{ device.serial_number }}</span><br /><br />
      <span><strong>N° d'appel :</strong> {{ device.call_number }}</span><br /><br />
      <span><strong>Marque :</strong> {{ device.brand }}</span><br /><br />
      <span><strong>Modèle :</strong> {{ device.model }}</span><br /><br />

      <div v-if="endpoint">
        <span><strong>Endpoint Etat :</strong>
          {{ endpoint.status.description }}</span><br /><br />
        <span><strong>Endpoint IMEI :</strong> {{ endpoint.imei }}</span><br /><br />
        <span><strong>Endpoint name :</strong> {{ endpoint.name }}</span><br /><br />
        <span><strong>Endpoint service profile :</strong>
          {{ endpoint.service_profile.name }}</span><br /><br />
        <span><strong>Endpoint @IP :</strong> {{ endpoint.ip_address }}</span><br /><br />
        <span><strong>Endpoint Dernière Modification :</strong>
          {{ dayjs(endpoint.last_updated).format('YYYY-MM-DD HH:mm:ss') }}</span><br /><br />
      </div>
      <div v-if="device.organization">
        <strong>Organisation :</strong>
        {{ device.organization.name }}
      </div>
      <div v-if="device.params">
        <strong>paramètres speedAlarm :</strong>
        {{ device.params.speedAlarm.value }}
      </div>
      <br /><br />
    </el-tab-pane>
    <el-tab-pane label="Sim Status">
      <div v-if="sim">
        <span><strong>Etat :</strong>
          <span v-if="sim.status.description == 'Activated'"> Activée</span>
          <span v-else> Problème</span></span><br /><br />
        <span><strong>IMSI :</strong> {{ sim.imsi }}</span><br /><br />
        <span><strong>Organisation :</strong> {{ sim.customer_org.name }}</span><br /><br />
        <span><strong>Modèle :</strong> {{ sim.model.description }}</span><br /><br />

        <strong>msisdn :</strong>
        {{ sim.msisdn }}

        <br /><br />
      </div>
      <div v-else></div>
    </el-tab-pane>
    <el-tab-pane label="Infos">
      <div v-if="info">
        <span><strong>DSN :</strong> {{ info.dsn }}</span><br /><br />
        <span><strong>Véhicle :</strong> {{ info.reference.vehicle_id }}</span><br /><br />
        <span><strong>Organisation :</strong>
          {{ info.reference.organization_id }}</span><br /><br />
        <span><strong>VRN :</strong> {{ info.reference.vrn }}</span><br /><br />
      </div>
      <div v-else></div>
    </el-tab-pane>
    <el-tab-pane label="Configuration">
      <label class="text-reader">
        <input type="file" @change="loadTextFromFile" />
      </label>
      <br />
      <div id="filecontent">
        <label>Configuration Actuelle:</label>
        <br />
        <textarea disabled v-model="fileText" style="width: 100%; height: 300px"></textarea>
        <br />
        <text-reader @load="fileText = $event"></text-reader>
        <label>Configuration Cible:</label>
        <br />
        <textarea v-model="fileTexttoedit" style="width: 100%; height: 300px"></textarea>
        <br />
        <text-reader @load="fileTexttoedit = $event"></text-reader>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Position">
      <div id="map" class="map"></div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster'
import { EmnifyAppToken } from '../../../config/config'
export default {
  data() {
    return {
      dialogTableDetails: false,
      map: null,
      marker: null,
      markerGroup: [],
      layerGroup: [],
      fileText: '',
      fileTexttoedit: '',
      attr: [],
      mapBoxtoken:
        'pk.eyJ1Ijoia291dGFpYmExOTk5IiwiYSI6ImNrdDdhMGNqeTBxOHIyb283dDZ3eHIzN2MifQ.b6EWolWAcJSOMjXCQoQHQA',
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      devices: 'devices/devices',
      device: 'devices/device',
      metadata: 'devices/metadata',
      member: 'auth/member',
      org: 'organisations/organisation',
      eminToken: 'emnify/authToken',
      sim: 'emnify/sim',
      info: 'devices/info',
    }),
  },
  props: ['index', 'row'],
  mounted() {
    this.getEmnifyToken()
    setTimeout(() => {
      this.drawMap()
      this.drawMarker()
    }, 2000)
  },
  methods: {
    ...mapActions({
      getauthapi: 'emnify/getauthtoken',
      getsims: 'emnify/getSims',
      getSim: 'emnify/getSim',
      getInfo: 'devices/getInfo',
      getEndpointByID: 'emnify/getEndpointByID',
    }),
    handleClick(obj) {
      let data = {}
      if (obj.props.label == 'Sim Status') {
        data = {
          auth_token: this.eminToken,
          sim_id: this.endpoint.sim.id,
        }
        this.getSim(data)
      } else if (obj.props.label == 'Infos') {
        data = {
          sn: this.device.serial_number,
          access_token: this.token,
        }
        this.getInfo(data)
      }
    },
    drawMap() {
      let satellite = L.tileLayer(
        'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=' +
        this.mapBoxtoken,
        {
          tileSize: 512,
          zoomOffset: -1,
          attribution:
            '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
      )

      this.map = L.map('map').setView([33.8, 9.4], 6)
      satellite.addTo(this.map)
    },
    loadTextFromFile(ev) {
      const file = ev.target.files[0]
      const reader = new FileReader()

      reader.onload = (e) => {
        this.fileText = e.target.result
        this.fileTexttoedit = e.target.result
        this.attr = this.fileTexttoedit.split(';')
      }
      reader.readAsText(file)
    },
    drawMarker() {
      this.markerGroup = new L.MarkerClusterGroup()
      var century21icon = L.icon({
        iconUrl: 'https://img.icons8.com/fluent/96/000000/place-marker.png',
        iconSize: [40, 40],
      })
      let latlng = L.GeoJSON.coordsToLatLng(this.device.status.loc.coordinates)
      this.marker = L.marker(latlng, {
        draggable: false,
        icon: century21icon,
      }).bindPopup(this.device.serial_number)

      this.map.addLayer(this.marker)
    },
    getEmnifyToken() {
      var data = {
        application_token: EmnifyAppToken,
      }
      this.getauthapi(data)
    },
  },
}
</script>
<style scoped>
.button-yoda:hover {
  background-color: #ffef9f;
  border: #ff9b2e;
  color: #ff9b2e;
}

.map {
  height: 700px;
  z-index: 0;
  width: 100%;
}
</style>
